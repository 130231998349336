import React from 'react'
import SEO from '~/components/seo'
import { MobileInfoWrapper, BureauDeStyle, AboutText } from '../utils/styles'
import Navigation from '../components/Navigation'

const ContactPage = () => (
  <>
    <SEO
      title="BDST | BY BEN TAVERNITI | Bureau de Stil"
      keywords={[
        `bureau de Stil`,
        `ben tavertini`,
        `kim kardashian`,
        'LA Fashion',
        'fashion',
        'joyce bonelli',
        'unravel project',
        'trend brand',
        'online shop',
      ]}
    />
    <Navigation />
    <MobileInfoWrapper>
      <div>
        <BureauDeStyle>BUREAU</BureauDeStyle>
        <BureauDeStyle>DE</BureauDeStyle>
        <BureauDeStyle>STIL</BureauDeStyle>
      </div>
      <div />
      <div />
      <BureauDeStyle style={{ marginTop: '100px' }}>INFO</BureauDeStyle>
    </MobileInfoWrapper>
    <div>
      <AboutText>
        {' '}
        Part fashion house, part creative collective, Bureau de Stil is a luxury
        brand launching in 2020 by Ben Taverniti. It is based at 7039 Sunset
        Boulevard in Los Angeles, California.
      </AboutText>
      <AboutText> 7039 Sunset Blvd., Los Angeles, CA 90016 </AboutText>
    </div>
  </>
)

export default ContactPage
